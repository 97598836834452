@import 'src/styles/_index.scss';

.info-fields-rct-component {
  color: #E8E8E8;

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0;
    width: 100%;

    > div {
      margin-bottom: 40px;
    }

    .input-label {
      color: #B0B0B0;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      padding-bottom: 4px;
    }
  }

  &__footer {
    margin-top: 64px;
    width: 100%;
  }
}

@media only screen and (max-width: $width-breakpoint-sm) {
  .info-fields-rct-component {
    &__content {
      gap: 40px;

      .input-label {
        font-size: 16px;
        line-height: normal;
      }

      .read-only-input-content {
        font-size: 20px;
        line-height: normal;
      }
    }
  }
}

#info-fields-edit-modal {
  .modal-rct-component__overlay {
    overflow-y: visible;
  }

  .info-fields-edit-modal-content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    min-width: 400px;

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: flex-end;
      margin-top: 64px;
    }
  }
}
