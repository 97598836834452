#save-build-modal {
  .button {
    width: 100%;
  }

  .form-rct-component {
    padding-bottom: 24px;
    padding-top: 40px;

    .button--primary {
      margin-top: 66px;
    }
  }
}
