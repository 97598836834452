@import 'src/styles/_index.scss';

.manage-jobs-table-rct-component {
  height: 100%;

  .table-rct-component {
    padding: 64px 240px;

    &__status-dropdown-tooltip {
      z-index: 100;

      .select-menu-rct-component {
        width: 100%;
      }
    }

    table {
      background: #202020;

      .statusDropdown-element {
        padding: 0;

        .table-status-dropdown {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          height: 100%;
          justify-content: space-between;
          padding: 16px;
          padding-top: 0;
        }
      }

      .configuration-viewer-link {
        color: $cool-blue;
        font-size: 20px;
        line-height: 32px;
        text-decoration: none;
      }
    }

    .rounded-select-input-rct-component {
      height: 100%;
      width: 100%;

      .custom-dropdown-icon * {
        color: white;
      }

      div {
        justify-content: flex-start;
      }

      .custom-menu {
        z-index: 1000;

        .select-menu-rct-component {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: $width-breakpoint-lgm) {
  .manage-jobs-table-rct-component {
    .table-rct-component {
      padding: 64px 120px;
    }
  }
}

@media only screen and (max-width: $width-breakpoint-lg) {
  .manage-jobs-table-rct-component {
    .table-rct-component {
      padding: 64px;
    }
  }
}
