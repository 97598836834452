@import 'src/styles/_index';

#vehicle-picker-modal {
  .modal-rct-component {
    &__overlay {
      overflow-y: initial;
    }

    &__content {
      padding-top: 40px;
    }
  }

  .rounded-select-input-rct-component {
    padding-bottom: 22px;
  }

  button {
    margin-top: 0;
  }

  .form-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
  }
}

#vehicle-picker-modal {
  @media only screen and (max-width: $width-breakpoint-sm) {
    .form-rct-component {
      gap: 32px;

      .rounded-select-input-rct-component {
        padding: 0;
      }

      .form-buttons-container {
        gap: 16px;
        margin-top: 8px;
      }
    }
  }
}
