@import 'src/styles/_index';

.form-field-rct-component {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $width-breakpoint-sm) {
    width: 100% !important;
  }

  .input-error {
    color: #FB6A6A;
    font-size: 14px;
    padding-top: 8px;
  }
}
