@import 'src/styles/_index.scss';

.select-menu-rct-component {
  background: #393939;
  border-top: 1px solid #535353;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 30%);
  font-family: $font-default;
  min-width: 120px;
  width: fit-content;
  z-index: 15;

  &__header {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 12px 16px 4px;

    &__title {
      color: #818181;
      font-size: 10px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__no-options {
    color: #818181;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    margin: 0 16px;
    padding: 11px 0;
  }

  &__content {
    &__grouped-options-container {
      > .option {
        color: #818181;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        margin: 0 16px;
        padding: 11px 0;
      }
    }

    &__option-container {
      &:hover {
        background: #535353;
      }

      &:not(:first-child) {
        .option {
          border-top: 1px solid #535353;
        }
      }

      .option {
        align-items: center;
        color: $text-02;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        justify-content: space-between;
        line-height: 18px;
        margin: 0 16px;
        padding: 11px 0;
        position: relative;
        z-index: 10;

        svg {
          position: absolute;
          right: 0;
          transform: scale(0.7);

          * {
            color: $state-positive;
          }
        }
      }
    }
  }
}
