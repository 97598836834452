@import 'src/styles/_index';

.wrap-picker-rct-component {
  align-items: center;
  background: #FFFFFF;
  box-sizing: content-box;
  color: black;
  display: flex;
  gap: 24px;
  width: 100%;

  &__options {
    align-items: center;
    display: flex;
    gap: 24px;
    justify-content: flex-start;
    width: 100%;
  }

  &__texture-picker {
    justify-content: center;
  }

  .selected-texture {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  .inner-label-prefix,
  .selected-texture-label {
    color: #878787;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .placeholder-label,
  .single-value,
  .selected-texture-value,
  .value {
    color: black;
    display: inline;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: $width-breakpoint-lgm) {
  .placeholder-label,
  .info-field-rct-component--inline .value,
  .single-value {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (max-width: $width-breakpoint-sm) {
  .wrap-picker-rct-component {
    .rounded-select-input-rct-component {
      .placeholder-container,
      .single-value {
        display: inline-block;
      }

      .dropdown-indicator {
        padding-right: 0;
      }

      .placeholder-label,
      .single-value {
        display: inline;
        text-overflow: ellipsis;
        width: calc(100%);
      }
    }
  }
}
