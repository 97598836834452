@import 'src/styles/_index';

.sidebar-configurator-footer {
  display: flex;
  flex-direction: column;
  padding: 16px;

  &__info {
    .bottom-info-divider {
      border: none;
      border-top: 1px solid #515151;
      height: 1px;
      margin: 16px 0;
    }
  }

  .label {
    color: #CCCCCC;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 4px;
  }

  .value {
    color: #FFFFFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.04em;
    line-height: 32px;
  }

  &__total {
    display: flex;
    flex-direction: column;

    .footnote {
      font-size: 12px;
    }
  }

  button {
    margin: 16px;
  }

  button.sidebar-configurator-footer__cancel-button {
    margin-top: 0;
  }
}
