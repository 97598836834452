.rounded-select-input-rct-component {
  cursor: default;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;

  * {
    color: white;
  }

  .inner-label-prefix,
  .custom-dropdown-icon * {
    color: #BEE5FF;
  }

  .input-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .inner-label-prefix {
    padding-right: 8px;
  }

  .placeholder-label {
    align-items: center;
    display: flex;
  }

  .custom-menu {
    position: relative;

    .select-menu-rct-component {
      left: 0;
      max-height: 300px;
      min-width: fit-content;
      overflow-y: auto;
      position: absolute;
      width: calc(100% - 14px);
    }
  }

  &--secondary {
    .inner-label-prefix {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    * {
      color: black;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
    }

    .inner-label-prefix,
    .custom-dropdown-icon * {
      color: #B7B7B7;
    }
  }
}
