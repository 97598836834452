@import 'src/styles/_index';

.carousel {
  align-items: center;
  display: flex;
  overflow: hidden;
  width: 100%;

  .navigation-arrow {
    color: black;
    cursor: pointer;
    flex-shrink: 0;
    height: 24px;
    width: 24px;

    svg > * {
      color: black;
    }

    &__right {
      transform: rotate(180deg);
    }
  }

  &__embla-ref {
    max-width: 400px;
    overflow: hidden;
    width: 100%;

    .embla__slide__inner {
      display: flex;
    }
  }

  &__slides-container {
    align-items: center;
    display: flex;
    min-width: 400px;

    > div:first-child {
      margin-left: 8px;
    }
  }

  &__dots {
    display: flex;
    justify-content: flex-end;
  }

  &__dot {
    background-color: $gray;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 8px;
    margin: 0 4px;
    padding: 0;
    width: 8px;
  }

  &__dot.selected {
    background-color: $deep-purple;
  }
}
