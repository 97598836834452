#cancel-order-modal {
  .modal-rct-component {
    &__overlay {
      max-width: 500px;
    }

    .modal-rct-component__title {
      padding-bottom: 40px;
    }

    .cancel-button {
      margin-top: 24px;
    }

    .cancel-order-modal-content {
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
        padding-bottom: 40px;
      }
    }
  }
}
