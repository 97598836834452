@import 'src/styles/_index';

.sidebar-rct-component {
  align-items: center;
  background-color: $gray-500;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: fit-content;

  &__logo-svg {
    flex-shrink: 0;
    padding-bottom: 16px;
    padding-left: calc($sidebar-horizontal-default-padding + 2px);
    padding-right: calc($sidebar-horizontal-default-padding + 95px);
    padding-top: 26px;
  }

  &__middle-section {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    width: 100%;

    &__title {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      padding: 0 $sidebar-horizontal-default-padding;
      padding-right: $sidebar-horizontal-item-right-padding;
    }
  }

  // hide middle section scrollbar
  &__middle-section::-webkit-scrollbar {
    width: 0;
  }

  /* Track */

  &__middle-section::-webkit-scrollbar-track {
    box-shadow: none;
  }

  /* Handle */

  &__middle-section::-webkit-scrollbar-thumb {
    background: white;
    box-shadow: none;
  }

  &__middle-section::-webkit-scrollbar-thumb:window-inactive {
    background: none;
  }

  &__footer {
    margin-top: auto;
    width: 100%;
  }
}
