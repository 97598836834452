@import 'src/styles/_index';

.button {
  align-items: center;
  border: none;
  color: black;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  gap: 10px;
  justify-content: space-between;
  line-height: 24px;
  padding: 16px 24px;
}

.button--primary {
  background-color: $cool-blue;
  color: $deep-purple;
}

.button--transparent {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 15%) 0 0 0 1px inset;
  color: #333333;
}

.button:disabled {
  background: #7B7B7B;
  color: white;
}
