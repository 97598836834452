$white: #ffffff;
$white-10: rgba($white, 0.1);
$black: #000000;
$off-white: #f6f6f6;
$charcoal: #444;
$grey: #8D8D8D;
$text-01: #F4F4F4;
$text-02: #C6C6C6;
$text-03: #6F6F6F;
$background: #131313;

$alto: #e0e0e0;
$east-bay: #39437d;
$silver: #ccc;
$tundora: #4a4a4a;

$light-black: #2d2e31;
$light-grey: #E8E8E8;
$primary: #1c6776;
$primary-light: #ccdee1;
$primary-light-text: #8faeb1;
$red: #d8403b;
$error-red: $red;
$cool-blue: #BEE5FF;
$cool-blue-60: #BEE5FF99;
$deep-purple: #0A0526;
$gray: #D9D9D9;
$gray-500: #393939;
$gray-300: #B0B0B0;
$gray-700: #202020;
$gray-900: #131313;

$state-positive: #51EA94;