@import 'src/styles/_index';

.modal-rct-component {
  align-items: center;
  background-color: rgba(0, 0, 0, 50%);
  color: white;
  display: flex;
  font-family: $font-default;
  font-size: 18px;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 16px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &__overlay {
    background: #131313;
    border: 1px solid #555555;
    box-shadow: 0 24px 56px rgba(0, 0, 0, 72%);
    max-height: 100%;
    overflow-y: auto;
    padding: 40px;
    position: relative;

    .close-button {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 56px;
    margin: 0;
  }
}

@media only screen and (max-width: $width-breakpoint-sm) {
  .modal-rct-component {
    padding: 72px 16px;

    .input-label {
      font-size: 16px;
      line-height: 24px;
    }

    .button {
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
      padding: 8px 8px 8px 16px;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__overlay {
      padding: 24px 16px;
    }
  }
}
