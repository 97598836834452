@import 'src/styles/_index';

.flash-messages-rct-component {
  height: fit-content;
  padding: 12px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  right: 40px;
  top: 40px;
  width: fit-content;
  z-index: 100000;
}
