@import 'src/styles/_index';

#side-panel-modal {
  .modal-rct-component {
    height: 100%;
    justify-content: flex-end;
    left: unset;
    padding: 0;
    right: 0;

    &__overlay {
      border: none;
      box-shadow: none;
      height: 100%;
      max-width: 544px;
      padding: 0;
    }

    &__content {
      height: 100%;
      padding: 0;
    }
  }

  .side-panel-rct-component {
    background: #202020;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 544px;
    width: fit-content;

    &__header {
      align-items: center;
      border-bottom: 1px solid $gray-500;
      display: flex;
      font-size: 20px;
      font-weight: 400;
      justify-content: space-between;
      line-height: 40px;
      padding: 24px 32px;
    }

    &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 32px;
      padding-bottom: 0;
      padding-top: 40px;

      &__field {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 40px;

        &__label {
          color: $gray-300;
          font-size: 16px;
          line-height: 24px;
        }

        &__value {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }

    &__footer {
      border-top: 1px solid $gray-500;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 24px;
      margin-top: 0;
      padding: 0 12px;
      padding-top: 40px;

      &__title {
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 24px;
      }

      .button {
        margin-left: 16px;
      }
    }

    .info-fields-rct-component__content {
      .input-label {
        font-size: 16px;
        line-height: 24px;
      }

      .read-only-input-content {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}
