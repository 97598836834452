.selected-vehicle-info-field {
  align-items: center;
  display: flex;
  gap: 8px;

  &__label {
    color: #878787;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__value {
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
