@import 'src/styles/_index';

.table-rct-component {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;

  .text-input-rct-component input {
    background: $gray-700;
    box-shadow: none;
  }

  &__header {
    color: $text-01;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 24px;
  }

  &__table-top-elements {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__add-button {
      font-size: 14px;
      font-weight: 400;
      height: 100%;
      line-height: 24px;
      padding: 12px;
    }
  }

  &__action-menu {
    position: absolute;
    z-index: 1000;

    .select-menu-rct-component {
      width: 224px;
    }
  }

  table {
    background: #131313;
    border-spacing: 0;
    color: #F4F4F4;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-style: normal;
    height: 100%;
    line-height: 18px;
    overflow: hidden;
    width: 100%;

    thead tr,
    tbody tr,
    tfoot tr {
      box-shadow: inset 0 1px 0 #393939;
      display: flex;
      padding: 0;

      th,
      td {
        padding: 16px;
      }
    }

    thead {
      font-weight: 600;

      tr {
        background: $gray-900;
      }
    }

    tbody,
    tfoot {
      font-weight: 400;

      tr {
        background: $gray-700;
      }
    }

    th,
    td {
      border: none;
      margin: 0;
      text-align: left;
      vertical-align: top;

      .sub-row-data {
        color: $gray-300;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    tbody {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: overlay;

      tr {
        flex: 0 !important;
      }

      td.actions-element {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0;

        > span {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          width: 100%;
        }

        &:hover {
          background: $gray-500;
        }
      }
    }

    tfoot {
      margin-top: auto;
      position: relative;

      tr td {
        width: 100%;
      }

      .footer-row-container {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        white-space: nowrap;
        width: 100%;

        &__right-side {
          svg:first-of-type {
            transform: rotate(180deg);
          }

          svg {
            color: #F4F4F4;
          }

          svg.disabled {
            color: #393939;
          }
        }

        &__left-side {
          .rounded-select-input-rct-component .custom-dropdown-icon * {
            color: #F4F4F4;
          }
        }

        &__left-side,
        &__right-side {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 16px;

          .rounded-select-input-rct-component {
            .custom-menu .select-menu-rct-component {
              bottom: 24px;
            }

            .dropdown-indicator {
              padding: 0;
            }

            .placeholder-label {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }

        &__divider {
          span {
            border-left: 1px solid #131313;
            bottom: 0;
            height: calc(100% - 1px);
            position: absolute;
          }
        }
      }
    }
  }
}
