/*
 global style rules for the app
 only universal/base styles should live here
*/

@import 'https://rsms.me/inter/inter.css';
@import 'src/styles/_index';

#mod-standalone-app-body {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-style: normal;
  height: 100%;
  line-height: 22px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

div {
  box-sizing: border-box;
}
// all styling other than the one above should go here or in a component's scss file
// reason is that the app can be embedded as a widget in another app and we don't want to mess with the parent app's styles
#mod-web-container {
  background: $background;
  color: $light-grey;
  display: flex;
  flex-direction: column;
  font-family: $font-default;
  height: 100%;
  overflow: auto;
  width: 100%;

  *::before,
  *::after {
    box-sizing: inherit;
  }

  .hidden {
    display: none !important;
  }
}
