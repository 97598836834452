#create-order-modal {
  .modal-rct-component {
    .modal-rct-component__title {
      padding-bottom: 40px;
    }

    .cancel-button {
      margin-top: 24px;
    }
  }
}
