@import 'src/styles/_index.scss';

.selected-vehicle-info-rct-component {
  background: $gray-500;
  display: flex;
  margin-bottom: 16px;
  margin-top: 24px;

  .selected-vehicle-info {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 24px;

    > ::after {
      content: '';
      flex: auto;
    }

    > :last-child {
      margin-top: 16px;

      span {
        color: $text-02;
      }
    }

    .edit-selected-vehicle {
      align-items: center;
      display: flex;
      gap: 8px;
      justify-content: center;
      width: fit-content;
    }
  }
}
