@import '../configurator-common.scss';

.simple-car-configurator-v2-rct-component {
  background-color: hsl(0deg, 0%, 100%);

  &__main-content {
    .header-nav-rct-component .logo {
      svg {
        color: black;
      }
    }
  }

  &__scene-container {
    display: flex;
    flex: 1 1;
    overflow: hidden;
    position: relative;

    .selected-vehicle-info-rct-component {
      left: 40px;
      margin: 0;
      padding: 16px;
      position: absolute;
      top: 40px;
      width: 280px;
      z-index: 10;
    }

    .image-carousel-slide {
      height: 100vh;
      object-fit: cover;
    }
  }

  .value {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-between;

    .floating-order-button-container {
      align-items: center;
      display: flex;
      justify-content: center;

      &__button {
        align-items: center;
        display: flex;
        height: 64px;
        justify-content: center;
        margin: 0;
        width: 140px;
      }
    }
  }

  .sidebar-configurator-footer {
    background: #393939;
    bottom: 40px;
    left: 40px;
    position: absolute;
    width: 280px;

    .footer-config-id {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .wrap-picker-rct-component {
    max-width: calc(100% - 64px);
  }
}

.simple-car-configurator-v2-rct-component {
  .car-configurator-header {
    gap: 0;
    justify-content: center;

    @media only screen and (max-width: $width-breakpoint-lg) {
      gap: 0;
    }
  }
}

@media only screen and (max-width: $width-breakpoint-lg) {
  .simple-car-configurator-v2-rct-component .wrap-picker-rct-component {
    max-width: 100%;
  }

  .simple-car-configurator-v2-rct-component {
    &__main-content {
      .header-nav-rct-component {
        margin: 0;
        padding: 24px 16px;
      }
    }

    &__scene-container {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 769px) and (min-width: $width-breakpoint-xs) {
  .simple-car-configurator-v2-rct-component {
    .wrap-picker-rct-component {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: $width-breakpoint-xs) {
  .simple-car-configurator-v2-rct-component {
    .sidebar-configurator-footer {
      bottom: unset;
      left: unset;
      position: relative;
      width: 100%;
    }

    .floating-wrap-picker-container {
      height: 120px;

      .wrap-picker-rct-component {
        gap: 0;
        height: 96px;

        .selected-texture {
          display: none;
        }

        &__options {
          align-items: start;
          height: 79px;
        }

        &__texture-picker {
          height: 56px;

          .carousel__dots {
            height: 16px;
          }
        }
      }
    }

    .sidebar-configurator-footer {
      &__total {
        &__value.value {
          font-size: 20px;
          height: 32px;
        }
      }

      .footer-config-id {
        gap: 0;

        .value {
          height: 32px;
        }

        .floating-order-button-container {
          button {
            bottom: 8px;
            position: absolute;
            right: 16px;
          }
        }
      }
    }
  }

  .scene-picker {
    margin-bottom: 106px;
  }
}

#floating-sidebar-modal {
  .simple-car-configurator-floating-sidebar {
    .selected-vehicle-info-rct-component {
      margin-top: 0;
      padding-left: 40px;
      padding-right: 24px;
    }
  }
}
