@import 'src/styles/_index';

#authentication-modal {
  .modal-rct-component {
    background-color: unset;

    @media only screen and (max-width: $width-breakpoint-sm) {
      .visibility-icon {
        top: 48px;
      }

      .form-section .forgot-pw {
        font-weight: 600;
      }

      .dive-into-modding {
        font-size: 16px;
        line-height: 24px;
        margin-top: 40px;

        br {
          content: '';
        }

        br::after {
          content: ' ';
        }
      }

      .forgot-password-form-rct-component {
        h1 {
          font-size: 32px;
          font-weight: 700;
          line-height: 40px;
        }

        .description {
          font-size: 16px;
          line-height: 24px;
        }

        .form-rct-component {
          gap: 40px;
        }
      }
    }
  }
}
