.car-configurator-image-carousel {
  .carousel__embla-ref .embla__slide__inner {
    display: flex;
    justify-content: center;
  }

  .carousel__embla-ref {
    max-width: unset;
  }
}
