@import 'src/styles/_index.scss';

#floating-sidebar-modal {
  .modal-rct-component {
    justify-content: flex-start;
    padding: 0;

    .sidebar-rct-component {
      &__logo-svg {
        padding-left: calc($sidebar-horizontal-default-padding + 2px + 42px);
        padding-right: calc($sidebar-horizontal-default-padding + 95px - 42px);
      }
    }

    .sidebar-title {
      display: flex;
      justify-content: space-between;
    }

    &__overlay {
      height: 100%;
      padding: 0;

      .close-button {
        left: 24px;
        z-index: 10;

        svg {
          height: 40px;
          width: 40px;
        }
      }
    }

    &__content {
      height: 100%;

      .floating-sidebar {
        height: 100%;
      }
    }
  }
}
