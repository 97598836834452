@import '_mixins';

@import 'https://rsms.me/inter/inter.css';
@import 'https://fonts.googleapis.com/css?family=Roboto';
$font-default: 'Inter', 'Roboto', 'Rubik', sans-serif;
#root {
  font-family: $font-default;
}
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-light: 300;

/* IMPORT_PT: update these as the project sees fit*/
$font-size-xl-4: fluid-size(120px, 300px);
$font-size-xl-3: fluid-size(72px, 96px);
$font-size-xl-2: fluid-size(24px, 64px);
$font-size-xl-1: fluid-size(48px, 56px);

$font-size-lg-4: fluid-size(28px, 64px);
$font-size-lg-3: fluid-size(32px, 48px);
$font-size-lg-2: fluid-size(16px, 42px);
$font-size-lg-1: fluid-size(30px, 36px);

$font-size-md-4: fluid-size(18px, 36px);
$font-size-md-3: fluid-size(22px, 36px);
$font-size-md-2: fluid-size(22px, 30px);
$font-size-md-1: fluid-size(18px, 24px);

$font-size-sm-3: fluid-size(18px, 22px);
$font-size-sm-2: fluid-size(16px, 18px);
$font-size-sm-1: fluid-size(14px, 18px);

$font-size-xs-3: fluid-size(12px, 14px);
$font-size-xs-2: fluid-size(10px, 12px);
$font-size-xs-1: fluid-size(8px, 10px);