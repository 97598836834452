@import 'src/styles/_index';

.address-input-rct-component {
  .text-input-rct-component {
    margin: 0;
  }

  &__suggestions-container {
    position: relative;
    z-index: 10;

    ul {
      background: $gray-500;
      border-top: 1px solid #535353;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 30%);
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      width: 100%;

      li {
        align-items: baseline;
        color: $text-02;
        display: flex;
        padding: 12px 16px;
        text-align: start;

        strong {
          padding-right: 10px;
        }
      }

      li:hover,
      li:focus {
        background: rgba(255, 255, 255, 10%);
        color: #FFFFFF;
      }

      > li:not(:last-child) {
        border-bottom: 1px solid #535353;
      }
    }
  }
}
