.label-with-icon-rct-component {
  align-items: center;
  color: #E8E8E8;
  display: flex;
  font-size: 16px;
  justify-content: center;
  line-height: 24px;

  &--clickable {
    cursor: pointer;
  }

  span {
    margin-left: 8px;
  }
}
