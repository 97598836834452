@import 'src/styles/_index';

.user-info-rct-component {
  &__fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 80px;

    .info-fields-rct-component {
      &__content {
        flex-direction: column;
      }

      .button[type='submit'] {
        margin-top: 24px;
      }

      .alternative-divider {
        color: $gray-300;
        display: flex;
        gap: 16px;
        margin-top: -40px;

        hr {
          align-self: center;
          border-color: $gray-500;
          height: 0;
          margin: 0;
          padding: 0;
          vertical-align: middle;
          width: 100%;
        }
      }

      .email-customer-with-code-button {
        border: 1px solid $cool-blue;
        color: white;
        width: 100%;
      }

      .dealer-code-input {
        input {
          background: transparent;
          border: 1px solid $white;
          box-shadow: none;
        }

        .input-icons-container {
          .icon-container.edit-icon {
            svg {
              height: 24px;
              width: 24px;
            }
          }

          .icon-container.copy-icon {
            background: $cool-blue;
            height: 100%;
            margin-right: -16px;
            width: 64px;

            svg {
              color: $gray-900;
            }
          }
        }
      }
    }
  }
}
