@import 'src/styles/_index';

.text-input-rct-component {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: input-default-margin;
  position: relative;

  svg {
    color: $cool-blue;
    cursor: pointer;
  }

  .input-label {
    align-items: center;
    color: $text-02;
    display: flex;
    flex-direction: column;
    flex-direction: row;
    font-size: 12px;
    gap: 9px;
    line-height: 16px;
    padding-bottom: 8px;
  }

  .input-container {
    background: #262626;
    display: flex;
    height: fit-content;
    position: relative;

    svg {
      color: $cool-blue;
    }

    .input-icons-container {
      align-items: center;
      display: flex;
      gap: 16px;
      height: 100%;
      justify-content: right;
      position: absolute;
      right: 16px;
      width: fit-content;

      .icon-container {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }

  input {
    background: #262626;
    border: none;
    box-shadow: inset 0 -1px 0 #6F6F6F;
    color: $light-grey;
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 20px;
    max-height: 20px;
    padding: 16px;

    ::placeholder {
      color: $text-03;
    }

    &:focus {
      outline: none;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
    color: $light-grey !important;
  }

  &--inner-label-input {
    flex-direction: row;

    .input-label {
      align-items: center;
      border: 1px solid $cool-blue-60;
      border-right: 0;
      color: $grey;
      font-size: $font-size-md-1;
      font-weight: bold;
      justify-content: center;
      line-height: 22px;
      padding: 16px;
      padding-right: 8px;
    }

    input {
      border-left: 0;
      padding-left: 0;
    }
  }

  .input-disabled-label {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }

  .read-only-input-content {
    font-size: 28px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
