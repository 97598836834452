@import 'src/styles/_index';

.tab-navigator-rct-component {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabs-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    width: 100%;

    @media only screen and (max-width: $width-breakpoint-sm) {
      margin-bottom: 24px;
    }

    :not(:first-child) {
      margin-left: 24px;
    }

    .tab {
      align-items: center;
      color: $white-10;
      display: flex;
      font-size: 48px;
      font-weight: 700;
      justify-content: center;
      line-height: 56px;
      padding-bottom: 8px;

      @media only screen and (max-width: $width-breakpoint-sm) {
        font-size: 32px;
        line-height: 40px;
      }

      &__active {
        border-bottom: 4px solid $cool-blue;
        color: $white;
      }
    }
  }

  .routes-container {
    display: flex;
    flex: 1;
  }
}
