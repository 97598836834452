@import 'src/styles/_index';

.sidebar-configurator-items {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  width: 100%;

  &__item-container {
    .sidebar-item {
      background: #484848;
      padding: 16px $sidebar-horizontal-default-padding;
      padding-right: $sidebar-horizontal-item-right-padding;
    }
  }

  .sidebar-sub-item-container {
    display: flex;

    .sidebar-sub-item {
      border-color: #555555;
      border-style: solid;
      border-width: 1px 0;
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 4px;
      padding: 16px $sidebar-horizontal-item-right-padding 24px $sidebar-horizontal-default-padding;

      &__title {
        color: $silver;
        display: flex;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        justify-content: space-between;
        line-height: 24px;
      }

      &__swipe-delete {
        align-items: center;
        background: #E33F53;
        display: flex;
        justify-content: center;
        transition-duration: 0.3s;
        transition-property: width;
        width: 95px;
      }

      &__description {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
      }

      &__footer {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
