@import 'src/styles/_index.scss';

.user-profile-rct-component {
  display: flex;
  gap: 56px;
  line-height: 56px;
  margin-left: 240px;
  padding: 64px 0;

  .avatar-section {
    position: relative;

    h1 {
      font-size: 48px;
      font-weight: 400;
      line-height: 56px;
      margin-bottom: 40px;
      margin-top: 0;
    }
  }

  .tabs-container {
    .tab {
      border-bottom: 2px solid #4589FF;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 12px 40px;
    }
  }
}

@media only screen and (max-width: $width-breakpoint-lgm) {
  .user-profile-rct-component {
    margin-left: 128px;
  }
}

@media only screen and (max-width: $width-breakpoint-lgm) {
  .user-profile-rct-component {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: $width-breakpoint-mdm) {
  .user-profile-rct-component {
    margin-left: 64px;
  }
}
