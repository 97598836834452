@import 'src/styles/_index';

.login-rct-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .sign-up-content {
    color: $text-02;
    display: flex;
    font-weight: normal;
    justify-content: flex-end;
    margin-right: 40px;
    margin-top: 49px;

    :last-child {
      color: $cool-blue;
      cursor: pointer;
    }
  }
}
