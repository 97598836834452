.form-rct-component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 400px;
  width: 100%;

  .form-input {
    margin: 0;
  }

  :first-child .form-input {
    margin-top: 0;
  }

  button {
    margin-top: 16px;
  }

  > * {
    width: 100%;
  }

  .form-error {
    color: #FB6A6A;
    font-size: 14px;
  }
}
