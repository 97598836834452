.add-customization-button-rct-component {
  align-items: center;
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-between;

  > div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 10px;
  }

  .icon {
    border: 2px solid rgba(190, 229, 255, 60%);
    border-radius: 15px 0 0 15px;
    padding: 14px 36px;
  }

  .label {
    border-color: rgba(190, 229, 255, 60%);
    border-radius: 0 15px 15px 0;
    border-style: solid;
    border-width: 2px 2px 2px 0;
    color: #BEE5FF;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 22px;
    padding: 21px 30px;
  }
}
