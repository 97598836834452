@import './configurator-common.scss';

#floating-sidebar-modal .modal-rct-component .sidebar-rct-component,
.car-configurator-rct-component .sidebar-rct-component {
  max-width: 311px;

  &__middle-section {
    overflow: auto;

    &__title {
      display: flex;
      flex-direction: column;

      .sidebar-title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .selected-vehicle-info-rct-component {
          display: flex;
          margin-bottom: 16px;
          margin-top: 24px;

          .selected-vehicle-info {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            line-height: 24px;

            > ::after {
              content: '';
              flex: auto;
            }

            > :last-child {
              margin-top: 16px;

              span {
                color: $text-02;
              }
            }

            .edit-selected-vehicle {
              align-items: center;
              display: flex;
              gap: 8px;
              justify-content: center;
              width: fit-content;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $width-breakpoint-lg) {
  .car-configurator-rct-component {
    &__main-content {
      .header-nav-rct-component {
        margin: 0;
        padding: 24px;
      }
    }

    &__scene-container {
      flex-direction: column;
    }

    .sidebar-configurator-footer {
      background: #393939;
      display: flex;
      flex-direction: column;
      width: 100%;

      &__total {
        flex-direction: row;
        justify-content: space-between;
        padding: 40px;
      }

      .button {
        margin: 0 40px 40px;
      }
    }
  }
}
