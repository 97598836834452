@import 'src/styles/_index';

.form-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  .forgot-pw {
    color: $cool-blue;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 28px;
  }
}
