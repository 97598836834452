.avatar-rct-component {
  align-items: center;
  display: flex;
  height: 124px;
  justify-content: center;
  position: relative;
  width: 124px;

  &__initials {
    color: black;
    font-size: 80px;
    position: absolute;
    z-index: 3;
  }

  &__circle {
    background: linear-gradient(180deg, #BEE5FF 0%, #E06060 100%);
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  &__circle-blur {
    background: linear-gradient(180deg, #BEE5FF 0%, #E06060 100%);
    bottom: 0;
    filter: blur(28px);
    height: 84px;
    position: absolute;
    right: 20px;
    width: 84px;
  }
}
