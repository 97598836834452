@import 'src/styles/_index.scss';

.simple-car-configurator-rct-component,
.car-configurator-rct-component {
  background-color: white;
  display: flex;
  height: 100%;
  width: 100%;

  &__main-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .header-nav-rct-component .logo {
      svg {
        color: black;
      }
    }
  }

  &__scene-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;

    .floating-wrap-picker-container {
      background: white;
      height: 146px;
      padding: 24px 16px;
      position: absolute;
      width: 100%;
      z-index: 10;

      .wrap-picker-rct-component {
        height: 100%;
        justify-content: space-between;
      }
    }
  }

  canvas {
    outline: none;
    position: absolute;
  }

  .wrap-picker-rct-component {
    width: 100%;

    &__options {
      .rounded-select-input-rct-component {
        .single-value .placeholder-label {
          max-width: 15ch;
          width: 15ch;
        }
      }

      .selected-texture-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (max-width: $width-breakpoint-lgm) {
  .wrap-picker-rct-component {
    gap: 8px;

    &__options {
      gap: 16px;
    }

    .single-value,
    .placeholder-label,
    .selected-texture-value {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .car-configurator-header .label-with-icon-rct-component span {
    display: none;
  }
}

@media only screen and (max-width: 769px) and (min-width: $width-breakpoint-xs) {
  .simple-car-configurator-rct-component {
    .wrap-picker-rct-component {
      &__texture-picker {
        width: 368px;

        .carousel__embla-ref {
          max-width: 320px;

          .embla__slide__inner {
            padding: 0 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $width-breakpoint-xs) {
  .car-configurator-rct-component,
  .simple-car-configurator-rct-component {
    .floating-wrap-picker-container {
      border: 1px solid #B0B0B0;
      bottom: 0;
      margin: 16px;
      padding: 16px;
      width: calc(100% - 32px);

      .wrap-picker-rct-component {
        flex-direction: column;
        gap: 16px;

        &__options {
          flex-wrap: wrap;
          gap: 16px;
          justify-content: flex-start;
          width: 100%;

          .rounded-select-input-rct-component,
          .info-field-rct-component {
            justify-content: flex-start;
            width: 100%;

            .select-menu-rct-component {
              bottom: 32px;
            }
          }
        }

        &__texture-picker {
          align-items: center;
          display: flex;
          flex-direction: column;

          .carousel {
            gap: 16px;
            justify-content: center;

            &__embla-ref {
              max-width: 246px;
            }
          }

          .carousel__dots {
            align-items: center;
            color: black;
            display: flex;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;

            span {
              margin-right: 16px;
            }
          }
        }
      }
    }

    &__main-content {
      .header-nav-rct-component {
        margin: 0;
        padding: 16px;
      }
    }

    .sidebar-configurator-footer {
      &__total {
        flex-direction: row;
        justify-content: space-between;
      }

      .button {
        margin: 0 16px 16px;
      }
    }
  }
}
