@import 'src/styles/_index';

.dive-into-modding {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 64px;

  .start-modding {
    color: $cool-blue;
  }
}
