@import 'src/styles/_index';

.reset-password-form-rct-component {
  color: white;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;

  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
  }

  .description {
    font-family: $font-default;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 24px;
  }
}
