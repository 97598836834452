@import 'src/styles/_index';

.scene-picker {
  align-items: center;
  bottom: 0;
  color: #B0B0B0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding-bottom: 24px;
  position: absolute;
  width: 100%;

  div {
    display: flex;
    gap: 16px;
  }
}

@media only screen and (max-width: $width-breakpoint-xs) {
  .scene-picker {
    margin-bottom: 162px;
  }
}
