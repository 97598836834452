.back-button-rct-component {
  align-items: center;
  display: flex;

  svg {
    cursor: pointer;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 8px;
  }
}
