@import 'src/styles/_index';

@mixin backgroundify($color) {
  background-color: $color;

  &:hover {
    background-color: darken($color, 5%);
  }

  &:active {
    background-color: darken($color, 15%);
  }
}

.flash-message-rct-component {
  align-items: center;
  border-radius: 4px;
  bottom: 0;
  color: $white;
  display: flex;
  font-size: 1.2em;
  height: fit-content;
  justify-content: center;
  pointer-events: all;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
  width: fit-content;

  @include backgroundify(#3D3D3D);

  > svg,
  > span {
    margin: 12px;
  }

  > span {
    font-family: Roboto, Inter, Rubik, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  > .indicator {
    margin-right: 0;
  }

  .indicator {
    align-self: stretch;
    width: 4px;
    z-index: 100000;
  }

  &.success {
    .indicator {
      background-color: #51EA94;
    }
  }

  &.error {
    @include backgroundify($error-red);

    .indicator {
      background-color: $error-red;
    }
  }
}

$open-duration: 0.75s;
$wait-duration: 2s;
$close-duration: 0.5s;

$delay-from-open-to-close: $open-duration + $wait-duration;

$open-anim: open $open-duration;
$close-anim: close $close-duration forwards;

@keyframes open {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes close {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.flash-message-rct-component.open-anim {
  animation: $open-anim;
}

.flash-message-rct-component.stay-open {
  animation: none;
}

.flash-message-rct-component.close-after-wait {
  animation: $close-anim $delay-from-open-to-close;
}

.flash-message-rct-component.close-immediate {
  animation: $close-anim;
}
