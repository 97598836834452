@import 'src/styles/_index.scss';

.car-configurator-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  width: 100%;

  &__hamburger-header {
    align-items: center;
    color: black;
    display: flex;
    gap: 24px;
    justify-content: flex-start;
    width: 100%;
  }

  &__selected-vehicle-info {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
  }

  &__action-icons {
    align-items: center;
    color: black;
    display: flex;
    gap: 24px;
    justify-content: flex-end;

    > div {
      white-space: nowrap;
    }

    .edit {
      align-items: center;
      display: flex;
      font-size: 16px;
      gap: 8px;
      line-height: 24px;
      white-space: nowrap;
    }
  }

  .label-with-icon-rct-component {
    color: black;
  }
}

.car-configurator-header {
  @media only screen and (min-width: $width-breakpoint-lg) {
    .car-configurator-header__action-icons {
      .edit {
        margin-left: 8px;
        margin-right: auto;
      }
    }
  }

  @media only screen and (max-width: $width-breakpoint-lg) {
    gap: 24px;
  }

  @media only screen and (max-width: $width-breakpoint-xs) {
    gap: 0;

    &__hamburger-header {
      gap: 16px;

      .hamburger-menu-icon {
        height: 24px;
        width: 24px;
      }

      .header-logo-icon {
        height: 24px;
        width: 100px;
      }
    }

    &__action-icons {
      font-size: 16px;
      gap: 16px;

      svg {
        flex-shrink: 0;
      }
    }
  }
}
