.wrap-picker-rct-component__texture-picker {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 464px;
  width: fit-content;

  &__options-no-carousel {
    display: flex;
    gap: 16px;
  }

  &__texture {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: center;
    position: relative;
    width: 24px;

    img {
      border-radius: 50px;
      height: 24px;
      width: 24px;
    }

    &--selected {
      img {
        transform: scale(0.65);
      }

      .texture-selection-border {
        border: 2px solid #A7A7A7;
        border-radius: 50%;
        box-sizing: border-box;
        height: 24px;
        position: absolute;
        width: 24px;
      }
    }
  }
}

.texture-option-rct-component__popover {
  z-index: 15;
}
