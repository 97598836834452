@import 'src/styles/_index';

.text-area__container {
  width: 100%;
}

.text-area__label {
  color: $east-bay;
  font-family: 'Lato Bold', sans-serif;
  font-size: 14px;
  padding-bottom: 10px;
  padding-left: 3px;
}

textarea {
  border: 1px solid $silver;
  color: $tundora;
  font-size: 14px;
  height: 380px;
  margin: 0;
  padding: 15px;
  width: 100%;

  &::placeholder {
    color: $alto;
    font-family: 'Lato Regular', sans-serif;
    font-size: 14px;
  }
}
