#mod-loader {
  align-items: center;
  background-color: #000000BF;
  display: none;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;

  > div {
    width: 350px;
  }
}
