.order-form-modal-rct-component {
  .modal-rct-component {
    &__overlay {
      max-width: 482px;

      .form-rct-component {
        max-width: unset;
      }
    }

    &__sub-title {
      font-size: 16px;
      padding-bottom: 24px;
    }
  }
}
