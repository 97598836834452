@import '_mixins';

$width-breakpoint-xl: 1690px;
$width-breakpoint-lgm: 1440px;
$width-breakpoint-lg: 1280px;
$width-breakpoint-mdm: 1024px;
$width-breakpoint-md: 980px;
$width-breakpoint-sm: 768px;
$width-breakpoint-xs: 480px;
$width-breakpoint-xxs: 320px;

$height-breakpoint-xs: 480px;

/* IMPORT_PT: update these as the project sees fit */
$spacing-xxl: between(32px, 60px, $width-breakpoint-xxs, $width-breakpoint-xl);
$spacing-xl: between(20px, 40px, $width-breakpoint-xxs, $width-breakpoint-xl);
$spacing-lg: between(16px, 32px, $width-breakpoint-xxs, $width-breakpoint-xl);
$spacing-m: between(12px, 20px, $width-breakpoint-xxs, $width-breakpoint-xl);
$spacing-sm: between(8px, 16px, $width-breakpoint-xxs, $width-breakpoint-xl);
$spacing-xs: between(4px, 8px, $width-breakpoint-xxs, $width-breakpoint-xl);
$spacing-xxs: between(0px, 4px, $width-breakpoint-xxs, $width-breakpoint-xl);


$sidebar-horizontal-default-padding: 40px;
$sidebar-horizontal-item-right-padding: 24px;

$input-default-margin: 10px 16px 0 0;